<template>
  <v-card>
    <div v-if="getSelectedPlan && getSelectedPlan.priceId !== 4">
      <v-card-text>
        <v-card outlined flat>
          <v-card-text>
            <card
              ref="card"
              class="stripe-card"
              :class="{ complete }"
              :stripe="stripePublicKey"
              @change="complete = $event.complete"
            />
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-text pa-0 ma-0>
        <v-img src="@/assets/images/stripeimg.jpg" />
      </v-card-text>
      <!-- <div class="text-center">
        <span class="text--red">You will not get charge now</span>
      </div> -->
      <!-- <v-divider></v-divider> -->
    </div>

    <v-card-actions>
      <v-btn large text @click="step = step - 1">Back</v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="submit" color="yellow" width="150" large>Submit</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Card, createToken } from "vue-stripe-elements-plus";
// import accountService from "@/modules/Account/service.js";

export default {
  data() {
    return {
      cards: [],
      complete: false,
      stripePublicKey: process.env.VUE_APP_STRIPE_PK,
      stripe: null,
    };
  },
  components: {
    Card,
  },
  computed: {
    ...mapGetters("auth", ["getStep", "getSelectedPlan"]),
    step: {
      get: function () {
        return this.getStep;
      },
      set: function (val) {
        this.setStep(val);
      },
    },
  },
  methods: {
    ...mapActions("auth", ["setStep"]),
    async submit() {
      try {
        let data = {};
        if (this.getSelectedPlan.priceId !== 4) {
          data = await createToken();
        }

        // const card = await accountService.addCard({
        //   card: {
        //     customer_id: this.currentBusiness.customer_id,
        //     source: data.token.id,
        //   },
        // });

        this.$emit("submit", data);
      } catch (error) {
        // console.log("error adding card", error);
        this.isLoading = false;
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: error.data.message,
          });
      }
    },
    nextWindow() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields"),
          });
      } else {
        this.$emit("submit", this.Business);
      }
    },
  },
};
</script>

<style scoped></style>
