<template>
  <v-card>
    
      <v-row>
        <v-col cols="12" sm="12">
          <v-text-field
            v-model="Business.name"
            :error-messages="nameErrors"
            :hide-details="nameErrors.length === 0"
            outlined autofocus
            @focus="$event.target.select()"
            dense
            label="Business Name"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-text-field
            v-model="Business.phoneNumber"
            label="Business Phone"
            outlined
            @focus="$event.target.select()"
            dense
            :error-messages="phoneNumberErrors"
            :hide-details="phoneNumberErrors.length === 0"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-text-field
            v-model="Business.email"
            :error-messages="emailErrors"
            :hide-details="emailErrors.length === 0"
            label="Business Email"
            outlined
            @focus="$event.target.select()"
            dense
          ></v-text-field>
        </v-col>
      </v-row>

    <v-divider></v-divider>
    <v-card-actions>
      <v-btn large text @click="step = step - 1">Back</v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="nextWindow" color="primary" large>next</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
const { validationMixin } = require("vuelidate");
const { required, email, minLength } = require("vuelidate/lib/validators");
export default {
  data() {
    return {
      Business: {
        name: null,
        phoneNumber: null,
        email: null,
      },
    };
  },
  mixins: [validationMixin],
  validations: {
    Business: {
      name: {
        required,
        minLength: minLength(5),
      },
      phoneNumber: {
        required,
      },
      email: {
        email,
        required,
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["getStep"]),
    emailErrors() {
      const errors = [];
      if (!this.$v.Business.email.$dirty) return errors;
      if (!this.$v.Business.email) errors.push("Email is not valid");
      if (!this.$v.Business.email.required) errors.push("Email is required.");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.Business.name.$dirty) return errors;
      if (!this.$v.Business.name.minLength)
        errors.push("Field must be at least 5 characters long");
      if (!this.$v.Business.name.required)
        errors.push("Business Name is required.");
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.Business.phoneNumber.$dirty) return errors;
      if (!this.$v.Business.phoneNumber.required)
        errors.push("Phone Number is required.");
      return errors;
    },
    step: {
      get: function() {
        return this.getStep;
      },
      set: function(val) {
        this.setStep(val);
      },
    },
  },
  methods: {
    ...mapActions("auth", ["setStep"]),
    nextWindow() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields"),
          });
      } else {
        this.$emit("submit", this.Business);
        this.step = 3;
      }
    },
  },
};
</script>

<style scoped></style>
