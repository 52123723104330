<template>
  <div class="text-center">
    <v-card min-width="400" max-width="500">
      <v-card-text>
        <v-flex>
          <v-flex class="mb-3 display-2 font-weight-light">
            <span style="color: #50e3c2">smpl</span
            ><span class="font-weight-black" style="color: #383838">sale</span>
          </v-flex>
          <v-flex xs12>
            <div>Forgot Your Location Code</div>
            <small>No Problem</small>
          </v-flex>

          <v-text-field
            v-model="email"
            outlined
            dense
            :error-messages="emailErrors"
            label="Business Email Address"
          >
          </v-text-field>
        </v-flex>
        <v-layout>
          <v-spacer> </v-spacer>
          <v-btn @click="submit" color="primary">Send</v-btn>
        </v-layout>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="info" @click="$router.push({ path: '/login' })" text block
          >Found code?</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import authService from "../service";

const { validationMixin } = require("vuelidate");
const { required, email } = require("vuelidate/lib/validators");

export default {
  name: "authentication-login",
  data() {
    return {
      email: null,
    };
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields"),
          });
      } else {
        this.$Progress.start();
        authService
          .forgotCode({
            email: this.email,
          })
          .then(() => {
            // console.log("response test user", response);
            this.$swal(
              "You are all set",
              "Check your email for a login code",
              "success"
            );

            this.$router.push({ path: "/login" });
          })
          .catch((error) => {
            console.log("error on ", error);
            this.$swal(
              "Invalid Login",
              "Check email, use save email address that you use to create an account",
              "error"
            );
          });
      }
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      if (!this.$v.email.email) errors.push("Email is not valid");
      if (!this.$v.email.required) errors.push("Email is required.");
      return errors;
    },
  },
  mixins: [validationMixin],
  validations: {
    email: {
      email,
      required,
    },
  },
};
</script>
