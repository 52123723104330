<script>
export default {
  name: 'authentication-logout',
  render() {
    return false
  },
  created() {
    localStorage.removeItem(`${process.env.VUE_APP_STORAGE_PREFIX}token`)
    localStorage.removeItem(`${process.env.VUE_APP_STORAGE_PREFIX}organizationId`)
    this.$router.push({ path: '/' })
  },
}
</script>
