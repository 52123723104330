<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="12">
          <v-text-field
            v-model="User.email"
            :error-messages="userEmailErrors"
            :hide-details="userEmailErrors.length === 0"
            label="Email"
            autofocus
            outlined
            @focus="$event.target.select()"
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12">
          <v-text-field
            v-model="User.password"
            :append-icon="showPassword ? 'visibility_off' : 'visibility'"
            :type="showPassword ? 'text' : 'password'"
            :label="$t('labels.password')"
            v-on:keyup.enter="submit"
            :hide-details="passwordErrors.length === 0"
            outlined
            @focus="$event.target.select()"
            dense
            :error-messages="passwordErrors"
            counter="counter"
            @click:append="showPassword = !showPassword"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="nextWindow" color="primary" large="large">Next</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import authService from "../../service";
import defaultImage from "@/assets/images/google_signin_hover.png";
import hoverImage from "@/assets/images/google_signin.png";
import { mapGetters, mapActions } from "vuex";

const { validationMixin } = require("vuelidate");
const { required, email } = require("vuelidate/lib/validators");

export default {
  data() {
    return {
      User: {
        email: null,
        password: null,
      },
      showPassword: false,
    };
  },
  mixins: [validationMixin],
  validations: {
    User: {
      email: {
        email,
        required,
      },
      password: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["getStep"]),
    passwordErrors() {
      const errors = [];
      if (!this.$v.User.password.$dirty) return errors;
      if (!this.$v.User.password.required) errors.push("Password is required.");

      return errors;
    },
    userEmailErrors() {
      const errors = [];
      if (!this.$v.User.email.$dirty) return errors;
      if (!this.$v.User.email) errors.push("Email is not valid");
      if (!this.$v.User.email.required) errors.push("Email is required.");
      return errors;
    },
    step: {
      get: function () {
        return this.getStep;
      },
      set: function (val) {
        this.setStep(val);
      },
    },
  },
  methods: {
    ...mapActions("auth", ["setStep"]),
    nextWindow() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields"),
          });
      } else {
        this.$emit("login", this.User);
        this.step = 2;
      }
    },
    hover() {
      document.getElementById("google-btn").setAttribute("src", defaultImage);
    },
    unhover() {
      document.getElementById("google-btn").setAttribute("src", hoverImage);
    },
    checkResponse(response) {
      if (response.status === 400) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: "Invalid User",
          });
      } else {
        this.$emit("login", response);
        this.step = 2;
      }
    },
    init() {
      this.$gAuth
        .getAuthCode()
        .then(async (authCode) => {
          //on success
          return authService.Google.getSignUpToken({ authCode });
        })
        .then((response) => {
          this.checkResponse(response);
        })
        .catch((error) => {
          if (error.status && error.status === 404) {
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              .fire({
                icon: "error",
                title: error.data.message,
              });

            if (error.data && error.data.payload) {
              const payload = error.data.payload;
              // We can still see the google data for their sign up
              this.$router.push({
                name: "signup",
                params: {
                  firstName: payload.given_name,
                  lastName: payload.family_name,
                  email: payload.email,
                  verified: payload.email_verified,
                },
              });
            } else {
              // this.$router.push("/signup");
              // this.$emit("response", error);
            }
          } else if (error.error) {
            // Do nothing
            // // console.log("Pop up error most likely");
          } else {
            this.checkResponse(error);

            // this.$emit("response", error);
          }
        });
    },
  },
};
</script>

<style scoped></style>
