<template>
  <div>
    <v-card v-if="!done" outlined max-width="600">
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-stepper vertical v-model="step" class="elevation-0">
              <v-stepper-step :complete="step > 1" step="1">
                Create User
              </v-stepper-step>

              <v-stepper-content step="1">
                <Login @login="login" />
              </v-stepper-content>

              <v-stepper-step :complete="step > 2" step="2">
                Business Information
              </v-stepper-step>

              <v-stepper-content step="2">
                <Business @submit="business" />
              </v-stepper-content>

              <v-stepper-step :complete="step > 3" step="3">
                Plan You Like To Try
              </v-stepper-step>

              <v-stepper-content step="3">
                <Plan @submit="paln" />
              </v-stepper-content>

              <v-stepper-step :complete="step > 4" step="4">
                Identification
              </v-stepper-step>

              <v-stepper-content step="4">
                <CreditCard @submit="submit" />
              </v-stepper-content>
            </v-stepper>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          color="info"
          @click="$router.push({ path: '/login' })"
          text
          block
        >
          Already have Account?</v-btn
        >
      </v-card-actions>
      <v-dialog v-model="pleaseWait" hide-overlay persistent width="300">
        <v-card color="yellow">
          <v-card-text class="pa-10">
            Please stand by
            <v-progress-linear
              indeterminate
              color="black"
              class="mb-0 mt-1"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
    <v-card v-else width="400" outlined>
      <v-card-text>
        <div class="text-center" v-if="result">
          <h2 class="display-3">You're all set</h2>
          <h2 class="mt-5 title font-weight-regular">here is location code</h2>
          <h2 class="display-1 font-weight-black">
            {{ result.business.code }}
          </h2>
          <h2 class="mt-5 title font-weight-regular">You login email is</h2>
          <h2 class="title font-weight-bold">
            <samp>{{ result.business.email }}</samp>
          </h2>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <div class="text-center">
          <v-btn
            color="success"
            @click="$router.push({ path: '/login' })"
            block
            text
            >Login</v-btn
          >
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Login from "./SignUp/Login";
import Business from "./SignUp/Business";
import CreditCard from "./SignUp/CreditCard";
import Plan from "./SignUp/Plan";
import authService from "../service";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "signup",
  data() {
    return {
      pleaseWait: false,
      done: false,
      result: null,
      signUp: {
        Business: {
          name: null,
          phoneNumber: null,
          email: null,
        },
        User: {
          password: null,
          email: null,
        },
      },
    };
  },
  components: {
    Login,
    Business,
    CreditCard,
    Plan,
  },
  created() {
    this.setStep(1);
  },
  methods: {
    ...mapActions("auth", ["setStep", "setSelectedPlan"]),
    login(val) {
      if (val.code) {
        this.signUp.User.email = val.user.email;
        this.signUp.User.imageUrl = val.user.picture;
        this.signUp.User.name = val.user.name;
      } else {
        this.signUp.User = val;
      }
    },
    business(val) {
      this.signUp.Business = val;
    },
    paln(val) {
      this.setSelectedPlan(val);
      this.signUp.Plan = val;
    },
    submit(val) {
      this.signUp.Card = val;
      this.pleaseWait = true;
      authService
        .signup(this.signUp)
        .then((result) => {
          this.result = result.data;
          this.pleaseWait = false;
          // this.$swal(
          //   "You are all set",
          //   "Check your email for a login code",
          //   "success"
          // );
          // this.$router.push({ path: "/login" });
          this.done = true;
        })
        .catch((error) => {
          // this.$Progress.finish();
          this.pleaseWait = false;
          // console.log("error on ", error);
          this.$swal("Invalid Login", error.data.message, "error");
        });
    },
  },
  computed: {
    ...mapGetters("auth", ["getStep"]),
    step: {
      get: function () {
        return this.getStep;
      },
      set: function (val) {
        this.setStep(val);
      },
    },
  },
};
</script>
