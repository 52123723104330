

export default {
  namespaced: true,
  state: {
    step: 1,
    selectedPlan: null,
  },
  getters: {
    getStep(state) {
      return state.step
    },
    getSelectedPlan(state) {
      return state.selectedPlan
    },
  },
  actions: {
    setStep({ commit }, payload) {
      commit('SET_STEP', payload)
    },
    setSelectedPlan({ commit }, payload) {
      commit('SET_SELECTED_PLAN', payload)
    },
  },
  mutations: {
    SET_STEP(state, payload) {
      state.step = payload
    },
    SET_SELECTED_PLAN(state, payload) {
      state.selectedPlan = payload
    },
  },
}
