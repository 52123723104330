import backofficeService from '../../api/backoffice'
import publicService from '../../api/public'


export default {
  /**
   *  Get token from server
   * @param {} id
   * @param {*} pin
   * @param {*} locationCode
   * @param {*} deviceCode
   * @param {*} deviceName
   */
  authenticate(params) {
    return backofficeService.Api().post('auth/token', {
        email: params.email,
        password: params.password,
        code: params.code,
      })
      .then((response) => {
        // // console.log('response authenticate', response)
        localStorage.setItem(`${process.env.VUE_APP_STORAGE_PREFIX}token`, response.data.token)

        // localStorage.setItem(`${process.env.VUE_APP_STORAGE_PREFIX}organizationId`, response.data.token.organization.organizationID)
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },
  forgotCode(params) {
    return publicService.Api().post('public/business/sendLocationEmail', params)
      .then((response) => {
        // // console.log('response authenticate', response)
        // localStorage.setItem(`${process.env.VUE_APP_STORAGE_PREFIX}token`, response.data.token)

        // localStorage.setItem(`${process.env.VUE_APP_STORAGE_PREFIX}organizationId`, response.data.token.organization.organizationID)
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },

  signup(params) {
    return backofficeService.Api().post('business/signUp', params)
      .then((response) => {
        // // console.log('response authenticate', response)
        // localStorage.setItem(`${process.env.VUE_APP_STORAGE_PREFIX}token`, response.data.token)

        // localStorage.setItem(`${process.env.VUE_APP_STORAGE_PREFIX}organizationId`, response.data.token.organization.organizationID)
        return response.data
      })
      .catch((error) => {
        throw error
      })
  },
  /**
   * Remove the location code store if there exist one/
   */


  Google: {
    getSignUpToken(data = {}) {
      // data.password = 'GxntdzwfXwuLXtAwTBEn2@YwrcRAiReT'

      return backofficeService.Api().post('auth/google/user', data)
        .then((response) => {
          // // console.log('google token', response)
          // localStorage.setItem(`${process.env.VUE_APP_STORAGE_PREFIX}token`, response.data.token)
          return response.data
        })
        .catch((error) => {
          throw error
        })
    },
    getToken(data = {}, code) {
      data.code = code.code
      // data.password = 'GxntdzwfXwuLXtAwTBEn2@YwrcRAiReT'
      // // console.log('data receive', data)

      return backofficeService.Api().post('auth/token/google', data)
        .then((response) => {
          // // console.log('google token', response)
          localStorage.setItem(`${process.env.VUE_APP_STORAGE_PREFIX}token`, response.data.token)
          return response.data
        })
        .catch((error) => {
          // console.log('google error', error)
          throw error
        })
    },
  },

  

}