<template>
  <v-container
    id="login" style="background-color: #FFEB3B"
    grid-list-lg
    fill-height
    fluid
  >
    <v-layout align-center justify-center fill-height>      
      <v-flex shrink>
        <div class="text-center mb-5">
           <v-col cols="12" class="pointer mb-3 display-2 font-weight-bold" >
           <span>Welcome</span>
          </v-col>
        </div>
        <router-view></router-view>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>

import authService from "./store.js";
import RegisterStoreModule from "@/mixins/RegisterStoreModule";
export default {
  name: "authentication",
  mixins: [RegisterStoreModule],
  created() {
    this.registerStoreModule("auth", authService);
    const loginToken = localStorage.getItem(`${process.env.VUE_APP_STORAGE_PREFIX}token`)
    if (loginToken) {
      this.$router.push({path: '/'})
    }
  },
};
</script>
<style scoped>
/* #login {
  background-image: url(https://picsum.photos/1200/?random&blur);
  background-size: cover;
} */
</style>
