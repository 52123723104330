<template>
  <v-card>
    <v-card-text>
      <v-radio-group class="mt-0" v-model="selectedPlan" column>
        <v-radio          
          :value="plan"
          :key="index"
          v-for="(plan, index) in plans"
        >
          <template v-slot:label>
            <div v-if="plan.name !== 'Partner'">
              <div class="title">{{ plan.name }}</div>
              <div>{{ plan.amount | currency }}</div>
            </div>
            <div v-else>
              <div class="title">{{ plan.name }}</div>
            </div>
          </template>
        </v-radio>
      </v-radio-group>
    </v-card-text>
    <!-- <div class="text-center red--text"><span>You will not get charge now</span></div>
    <div class="text-center"><span>First month on us</span></div> -->
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn large text @click="step = step - 1">Back</v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="nextWindow" color="primary" large>next</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      selectedPlan: null,
      plans: [
        // { name: "Starter", amount: 19.99, priceId: 1 },
        // { name: "Basic", amount: 79.99, priceId: 2 },
        { name: "Everything", amount: 124.99, priceId: 3 },
        { name: "Partner", amount: '?', priceId: 4 },
      ],
    };
  },
  computed: {
    ...mapGetters("auth", ["getStep"]),
    emailErrors() {
      const errors = [];
      if (!this.$v.Business.email.$dirty) return errors;
      if (!this.$v.Business.email) errors.push("Email is not valid");
      if (!this.$v.Business.email.required) errors.push("Email is required.");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.Business.name.$dirty) return errors;
      if (!this.$v.Business.name.minLength)
        errors.push("Field must be at least 5 characters long");
      if (!this.$v.Business.name.required)
        errors.push("Business Name is required.");
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.Business.phoneNumber.$dirty) return errors;
      if (!this.$v.Business.phoneNumber.required)
        errors.push("Phone Number is required.");
      return errors;
    },
    step: {
      get: function() {
        return this.getStep;
      },
      set: function(val) {
        this.setStep(val);
      },
    },
  },
  watch: {
    async selectedPlan(val) {
      if (val && val.priceId === 4) {
        const { value: password } = await this.$swal.fire({
        title: 'Enter your password',
        input: 'password',
        inputLabel: 'Password',
        inputPlaceholder: 'Enter your password',
        inputAttributes: {
          maxlength: 10,
          autocapitalize: 'off',
          autocorrect: 'off'
        }
      })

      if (password === 'pak2312141') {
        this.$emit("submit", this.selectedPlan);
        this.step = 4;
      } else {
        this.selectedPlan = null
      }
      }
    }
  },
  methods: {
    ...mapActions("auth", ["setStep"]),    
    nextWindow() {
      if (!this.selectedPlan) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: true,
            timer: 6000,
          })
          .fire({
            icon: "error",
            title: "Select plan you like to try",
          });
      } else {
        this.$emit("submit", this.selectedPlan);
        this.step = 4;
      }
    },
  },
};
</script>

<style scoped></style>
