<template>
  <div class="text-center">
    <v-card min-width="400" max-width="500" outlined>
      <v-card-text>
        <v-flex>
          <v-flex
            class="mb-3 display-2 font-weight-light pointer"
            @click="home"
          >
            <span style="color: #50e3c2">smpl</span>
            <span class="font-weight-black" style="color: #383838">sale</span>
          </v-flex>
          <!-- <v-flex xs12>
            <span>Welcome Back</span>
          </v-flex> -->
          <!-- <GoogleLogin
            class="mt-3 mb-3"
            v-on:response="checkResponse"
            :code="code"
          >
          </GoogleLogin>

          <div class="mb-5">Or</div> -->
          <v-row>
            <v-col class="pr-0"> </v-col>
            <!-- <v-col class="shrink">
              <v-btn color="warning" @click="$router.push({ path: '/forgot' })"
                >forgot</v-btn
              >
            </v-col> -->
          </v-row>
          <!-- <v-text-field
            v-model="code"
            outlined
            ref="locationCode"
            dense
            :error-messages="codeErrors"
            label="Code"
          >
          </v-text-field> -->

          <v-text-field
            v-model="code"
            outlined
            ref="locationCode"
            dense
            :error-messages="codeErrors"
            label="Code"
          >
            <template slot="append">
              <v-btn small text @click="$router.push({ path: '/forgot' })"
                >forgot
              </v-btn>
            </template>
          </v-text-field>

          <v-text-field
            v-model="email"
            outlined
            dense
            :error-messages="emailErrors"
            label="Email"
          >
          </v-text-field>
          <v-text-field
            v-model="password"
            outlined
            dense
            :append-icon="showPassword ? 'visibility_off' : 'visibility'"
            :type="showPassword ? 'text' : 'password'"
            :label="$t('labels.password')"
            v-on:keyup.enter="submit"
            :error-messages="passwordErrors"
            @input="$v.password.$touch()"
            counter="counter"
            @click:append="showPassword = !showPassword"
          >
          </v-text-field>
        </v-flex>
        <v-row>
          <v-col>
            <v-btn text color="info" @click="$router.push({ path: '/forgot' })"
              >forgot password</v-btn
            >
          </v-col>
          <v-spacer> </v-spacer>
          <v-col>
            <v-btn :loading="loading" @click="submit" color="primary">{{
              $t("buttons.login")
            }}</v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider> </v-divider>
      <v-card-actions>
        <v-btn
          color="info"
          @click="$router.push({ path: '/signup' })"
          text
          block
          >Don't have an Account?</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import authService from "../service";
// import GoogleLogin from "./GoogleLogin";

const { validationMixin } = require("vuelidate");
const { required, email } = require("vuelidate/lib/validators");

export default {
  name: "authentication-login",
  data() {
    return {
      showPassword: false,
      email: null,
      loading: false,
      password: null,
      code: null,
    };
  },
  created() {
    // console.log('created in login')
    const localStoreCode = localStorage.getItem(
      `${process.env.VUE_APP_STORAGE_PREFIX}code`
    );
    if (localStoreCode) this.code = localStoreCode;
  },
  methods: {
    ...mapActions("global", ["initBusiness", "initUser"]),
    home() {
      window.open("https://smplsale.com", "_self");
    },
    checkResponse(response) {
      if (response.status === 400) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: "Invalid User",
          });
      } else {
        localStorage.setItem(
          `${process.env.VUE_APP_STORAGE_PREFIX}code`,
          this.code
        );
        this.$router.push({ name: "home" });
      }
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields"),
          });
      } else {
        this.loading = true;
        this.$Progress.start();
        console.log('sending', this.email, this.password)
        authService
          .authenticate({
            email: this.email,
            password: this.password,
            code: this.code,
          })
          .then((response) => {
            console.log('response', response)
            this.$Progress.finish();
            this.checkResponse(response);
            this.loading = false;
          })
          .catch((error) => {
            this.$Progress.finish();
            this.loading = false;
            // console.log("error on ", error);
            this.$swal("Invalid Login", error.data.message, "error");
          });
      }
    },
  },
  computed: {
    codeErrors() {
      const errors = [];
      if (!this.$v.code.$dirty) return errors;
      if (!this.$v.code.required) errors.push("Code is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      if (!this.$v.email.email) errors.push("Email is not valid");
      if (!this.$v.email.required) errors.push("Email is required.");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      if (!this.$v.password.required) errors.push("Password is required.");
      return errors;
    },
  },
  mixins: [validationMixin],
  validations: {
    email: {
      email,
      required,
    },
    code: {
      required,
    },
    password: {
      required,
    },
  },
};
</script>
